import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { mdiDownload } from "@mdi/js"

// Project components
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  FlexGrid,
  Heading,
  RichText,
  Wrapper,
} from "components"

const Sign = ({ title, image, downloadLink }) => (
  <Flex>
    <Flex flexDirection="column" flex={1}>
      {title && (
        <Heading size={100} fontWeight="bold" textAlign="center" p="layout.2">
          {title}
        </Heading>
      )}
      <AspectRatio ratio="4:5" borderRadius={8} bg="wash">
        {image && image.fluid && (
          <Image
            fluid={image.fluid}
            alt={image.alt}
            objectFit="contain"
            objectPosition="center"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "75%",
              height: "75%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
      </AspectRatio>

      <Flex justifyContent="center" mt="-20px">
        <Button
          iconAfter={mdiDownload}
          intent="default"
          appearance="primary"
          height={40}
          as="a"
          href={downloadLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          Download
        </Button>
      </Flex>
    </Flex>
  </Flex>
)

const SignsSection = ({ activeFilter, setActiveFilter, data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {data.signs && data.services && (
        <Wrapper id={id}>
          <Box overflow="hidden">
            <Flex
              flexWrap="wrap"
              m="-2px"
              css={{
                "> *": {
                  padding: "2px",
                },
              }}
            >
              {/* Set appearance primary on active item. All set to ghost when no filtering */}
              {data.services.map((service, index) => (
                <Box key={"serviceButton" + index}>
                  <Button
                    intent="secondary"
                    appearance={
                      activeFilter === service.service.uid ? "primary" : "ghost"
                    }
                    height={40}
                    onClick={() => {
                      if (activeFilter === service.service.uid) {
                        setActiveFilter("")
                      } else {
                        setActiveFilter(service.service.uid)
                      }
                    }}
                  >
                    {service.service.document.data.name &&
                      service.service.document.data.name.text}
                  </Button>
                </Box>
              ))}
            </Flex>
          </Box>

          <Box mt={["layout.4", "layout.6"]}>
            <FlexGrid
              content={[
                {
                  width: ["100%", null, "66.667%"],
                  children: (
                    <Flex
                      flexDirection="column"
                      flex={1}
                      p={["layout.2", "layout.5"]}
                      borderRadius={8}
                      bg="wash"
                    >
                      <RichText
                        content={{ html: data.bodyContent }}
                        size={400}
                        color="alt"
                      />
                    </Flex>
                  ),
                },
                ...data.signs.map(sign => ({
                  width: ["50%", null, "33.333%"],
                  children: <Sign {...sign} />,
                })),
              ]}
              gutterX={[2, 6, 6, 8]}
              gutterY={[4, 8]}
            />
          </Box>
        </Wrapper>
      )}
    </>
  )
}

export default SignsSection
