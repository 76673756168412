import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

import { Divider, NextPage, SEO } from "components"

// Page sections
import IntroSection from "./_introSection"
import SignsSection from "./_signsSection"

function SignagePage() {
  const staticData = useStaticQuery(graphql`
    {
      prismicSignage {
        data {
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_image {
            url
            alt
          }
          page_title {
            text
          }
          signage_description {
            html
          }
          next_page_image {
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          next_page_title {
            text
          }
          next_page_button_text
          next_page_button_link {
            slug
          }
          filters {
            service {
              uid
              document {
                ... on PrismicService {
                  uid
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }

      allPrismicSign(sort: { fields: data___title___text, order: ASC }) {
        nodes {
          data {
            title {
              text
            }
            sign {
              fluid(maxWidth: 512) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            download {
              url
            }
            service_types {
              service_type {
                uid
              }
            }
          }
        }
      }
    }
  `)
  const previewData =
    typeof window !== "undefined" && window.__PRISMIC_PREVIEW_DATA__
  const finalData = mergePrismicPreviewData({ staticData, previewData })

  const [activeFilter, setActiveFilter] = useState("")

  return (
    <>
      <SEO
        title={
          finalData.prismicSignage.data.seo_title &&
          finalData.prismicSignage.data.seo_title.text
        }
        description={
          finalData.prismicSignage.data.seo_description &&
          finalData.prismicSignage.data.seo_description.text
        }
        image={
          finalData.prismicSignage.data.seo_image &&
          finalData.prismicSignage.data.seo_image.url
        }
        imageAlt={
          finalData.prismicSignage.data.seo_image &&
          finalData.prismicSignage.data.seo_image.alt
        }
      />

      {/* ============ INTRO ============ */}
      <IntroSection
        id="signage-intro"
        data={{
          title:
            finalData.prismicSignage.data.page_title &&
            finalData.prismicSignage.data.page_title.text,
        }}
      />

      {/* ============ SIGNS ============ */}
      <SignsSection
        id="signage-signs"
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        data={{
          bodyContent:
            finalData.prismicSignage.data.signage_description &&
            finalData.prismicSignage.data.signage_description.html,
          services: finalData.prismicSignage.data.filters,
          signs:
            finalData.allPrismicSign &&
            finalData.allPrismicSign.nodes &&
            finalData.allPrismicSign.nodes
              .filter(sign => {
                let found = false
                sign.data.service_types &&
                  sign.data.service_types.forEach(type => {
                    if (
                      type.service_type &&
                      type.service_type.uid === activeFilter
                    ) {
                      found = true
                    }
                  })
                if (activeFilter === "" || found) {
                  return true
                } else {
                  return false
                }
              })
              .map(sign => ({
                title: sign.data.title && sign.data.title.text,
                image: sign.data.sign && sign.data.sign.fluid && sign.data.sign,
                downloadLink: sign.data.download && sign.data.download.url,
              })),
        }}
      />

      <Divider />

      {/* ============ NEXT PAGE ============ */}
      <NextPage
        id="signage-nextPage"
        image={finalData.prismicSignage.data.next_page_image}
        title={
          finalData.prismicSignage.data.next_page_title &&
          finalData.prismicSignage.data.next_page_title.text
        }
        buttonLabel={finalData.prismicSignage.data.next_page_button_text}
        buttonTo={
          "/" + finalData.prismicSignage.data.next_page_button_link.slug + "/"
        }
      />
    </>
  )
}

export default SignagePage
